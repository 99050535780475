import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { Inner } from 'styles/structure';
import Button from 'components/_ui/Button';

const NotFoundContainer = styled.div`
  min-height: 60vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 4.8rem 0;

  header {
    margin-bottom: 4rem;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />

    <NotFoundContainer>
      <Inner>
        <header>
          <h1>404</h1>
          <h3>Page Not Found</h3>
        </header>

        <Button as={Link} to="/">Go Home</Button>
      </Inner>
    </NotFoundContainer>
  </Layout>
);

export default NotFoundPage;
